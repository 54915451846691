import Layout from "./common/layout"
import Head from "./common/Head"
import Campaign from "./product/Campaign/Campaign"
import { useFormatter } from "helpers/i18n"

const ProductCampaign = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "AI ecosystem to optimize customer experience and revenue"
            : "Giải pháp AI tối ưu trải nghiệm khách hàng, tăng doanh thu"
        }
        description={
          currentLocale === "en"
            ? "OnPoint offers a big data & AI ecosystem that allows the creation of high-value analytics that optimizes customer experience, loyalty and revenues."
            : "OnPoint phát triển hệ sinh thái dữ liệu lớn và AI nhằm tối ưu hóa trải nghiệm, gia tăng lòng trung thành của người mua và đẩy mạnh doanh thu."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/product-3-meta.png"
        }
      />
      <Layout>
        <Campaign />
      </Layout>
    </>
  )
}

export default ProductCampaign
